import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import AzureAuthProvider from "./auth/AzureAuth";
import RootLayout from "./components/Layout";
import { UserRoleProvider } from "./contexts/UserRoleContext";
import ErrorBoundary from "./errorBoundary/ErrorBoundary";
import AttendanceCreationPage from "./pages/AttendanceCreationPage";
import AttendanceSearchPage from "./pages/AttendanceSearchingPage";
import EventRegistrationPage from "./pages/EventRegistrationPage";
import LuckyDrawPage, { allPrizeLoader } from "./pages/LuckyDraw";
import ViewPrize from "./pages/LuckyDraw/ViewPrize";
import UnavailablePage from "./pages/UnavailablePage";
import WinnerView from "./pages/WinnerView";
import RoleBasedRoute from "./route/RoleBasedRoute";
import QRCodePage from "./pages/QRCodePage";

const router = createBrowserRouter([
  {
    path: "/admin",
    element: <AdminApp />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        index: true,
        element: <Navigate to="/admin/register" replace />,
      },
      {
        element: <RoleBasedRoute roles={["write:em-event-register"]} />,
        children: [
          {
            path: "register",
            element: <EventRegistrationPage />,
          },
          {
            path: "attendance-management",
            element: <AttendanceSearchPage />,
          },
          {
            path: "attendance-management/create",
            element: <AttendanceCreationPage />,
          },
        ],
      },
      {
        element: <RoleBasedRoute roles={["write:em-event-lucky-draw"]} />,
        children: [
          {
            path: "lucky-draw",
            element: <LuckyDrawPage />,
            loader: allPrizeLoader,
          },
          {
            path: "lucky-draw/winners/:id",
            element: <ViewPrize />,
          },
        ],
      },
      {
        path: "*",
        element: <Navigate to="/unavailable" replace />,
      },
    ],
  },
  {
    path: "/",
    element: <GuestApp />,
  },
  {
    path: "/qrcode",
    element: <QrApp />,
  },
  {
    path: "/unavailable",
    element: <UnavailablePage />,
  },
  {
    path: "*",
    element: <Navigate to="/unavailable" replace />,
  },
]);

function AdminApp() {
  return (
    <AzureAuthProvider>
      <UserRoleProvider>
        <RootLayout />
      </UserRoleProvider>
    </AzureAuthProvider>
  );
}

function GuestApp() {
  return <WinnerView />;
}

function QrApp() {
  return <QRCodePage />;
}

function App() {
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-600 via-black to-blue-800 bg-[length:200%] animate-gradient-flow flex items-center justify-center">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
