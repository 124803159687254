import { EmployeeModel } from "../models/EmployeeModel";
import React from 'react';

const EmployeeCard: React.FC<{ employee: EmployeeModel, showPrize?: boolean }> = ({ employee, showPrize = false }) => {
  return (
    <div className="p-4 bg-gray-100 rounded-lg shadow-inner">
      <h2 className="text-2xl font-bold text-gray-800">Employee Details</h2>
      <p>
        <strong>Name:</strong> {employee.firstname +' '+ employee.lastname}
      </p>
      <p>
        <strong>Email:</strong> {employee.email}
      </p>
      <p>
        <strong>Timestamp:</strong> {employee.checkInTimestamp}
      </p>
      <p>
          <strong>Team Number:</strong> {employee.tableNumber || '-'}
      </p>
      <p>
          <strong>Shirt Size:</strong> {employee.organization}
      </p>
      <p>
        <strong>Food Note:</strong> {employee?.foodRestriction?.trim() || '-'}
      </p>
      <p>
          <strong>Check-in Number:</strong> {employee.doorGiftNumber > 0 ? employee.doorGiftNumber : '-'}
      </p>
    </div>
  );
};

export default EmployeeCard;